@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("Inter-Thin.ttf");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("Inter-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: local("Inter Medium"), local("Inter-Medium"),
    url("Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: local("Inter SemiBold"), local("Inter-SemiBold"),
    url("Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: local("Inter Bold"), local("Inter-Bold"),
    url("Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: local("Inter ExtraBold"), local("Inter-ExtraBold"),
    url("Inter-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: local("Inter Black"), local("Inter-Black"),
    url("Inter-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
